  import { ApplicationConfig, importProvidersFrom, PLATFORM_ID, inject, provideAppInitializer } from '@angular/core';
import {provideRouter, withInMemoryScrolling} from '@angular/router';
import {routes} from './app.routes';
import {provideClientHydration} from '@angular/platform-browser';
import {provideHttpClient, withFetch, withInterceptors} from '@angular/common/http';
import {provideAnimations} from '@angular/platform-browser/animations';
import {ConfigService} from './services/configService';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import {httpInterceptor} from './services/http-interceptor';
import {environment} from '../environments/environment';
import {routesPD} from './app.routes.pd';
  import {STORAGE_TOKEN} from '../oex-ui-kit/services/storage.service';
  import {StorageService} from './services/storageService';

export const initializeApplication = () => {
  return inject(ConfigService).initialize();
};

export const appConfig: ApplicationConfig = {
  providers: [
    // {provide: APP_SSR_COOKIES, useValue: ''},
    DatePipe,
    // provideServiceWorker('ngsw-config.json'),
    // Do not use withFetch() on server, because it doesn't support cookies. (node-fetch, issue)
    provideHttpClient(withFetch(), withInterceptors([httpInterceptor])),
    provideClientHydration(),
    provideAnimations(),
    provideRouter(
      environment.pd ? routesPD : routes,
      /*withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'disabled'
      })*/
      // withDebugTracing()
      // withPreloading(PreloadAllModules)
    ),
    provideAppInitializer(initializeApplication),
    {provide: STORAGE_TOKEN, useClass: StorageService}
  ]
};
