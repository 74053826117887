import { Injectable, inject } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {Observable} from 'rxjs/internal/Observable';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DefaultResolver {
  private meta = inject(Meta);


  /**
   * Resets meta tags
   */
  static resetMetaTags(meta: Meta) {
    const img = 'https://openexchange.intersystems.com/assets/img/logo.svg';
    if (environment.pd) {
      meta.addTag({name: 'og:title', content: 'InterSystems Partner Directory'});
      meta.addTag({
        name: 'og:description',
        content: 'InterSystems Partner Directory is a gallery of applications, solutions, tools, interfaces and adapters built with InterSystems Data Platforms: InterSystems IRIS, Caché, Ensemble, HealthShare, InterSystems IRIS for Health or which help with development, deployment, management and performance tuning for the solutions on Big Data, AI and Machine Learning, Interoperability and Scalability, Health, Finance, Retail'
      });
    } else {
      meta.addTag({name: 'og:title', content: 'InterSystems Open Exchange'});
      meta.addTag({
        name: 'og:description',
        content: 'InterSystems Open Exchange is a gallery of applications, solutions, tools, interfaces and adapters built with InterSystems Data Platforms: InterSystems IRIS, Caché, Ensemble, HealthShare, InterSystems IRIS for Health or which help with development, deployment, management and performance tuning for the solutions on Big Data, AI and Machine Learning, Interoperability and Scalability, Health, Finance, Retail'
      });
    }
    meta.addTag({name: 'og:image', content: img});
    meta.addTag({name: 'og:image:secure_url', content: img});
  }

  static setTitle(title: Title) {
    if (environment.pd) {
      title.setTitle('InterSystems Partner Directory');
    } else {
      title.setTitle('InterSystems Open Exchange');
    }
  }

  /**
   * Main resolve function
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return new Observable<any>(o => {
      DefaultResolver.resetMetaTags(this.meta);
      o.next();
      o.complete();
    });
  }
}
