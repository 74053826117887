import {HttpService} from '../../services/httpService';
import {BaseDataResolver, DataModel} from '../../services/base-data-resolver';
import { Injectable, inject } from '@angular/core';
import {AuthService} from '../../services/authService';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {AdminFilters} from '../../pages/home/homeData';
import {StorageService} from '../../services/storageService';
import {Observable} from 'rxjs/internal/Observable';
import {PartnersDirectoryService} from '../../services/partnersDirectory';
import {ICompany} from '../../services/api.types';

export class ICompanyCertificate {
  ID = -1;
  Name = ''
  Description = '';
  Order = 0;
  Count = 0;
  Image = ';'
}

// Company model
export class CompanyListModel extends DataModel {
  companies: ICompany[] = [];
  sorting = '';
  searchTerm = '';
  fltAdmin: AdminFilters = new AdminFilters();
  companyTypes: boolean[] = [];
  companyCerts: boolean[] = [];
  countries = '';
  isSolutions = false;
  notWorldwide = false;
  isCertified = false;
  type = '';
  certificates: ICompanyCertificate[] = [];
  isAnyCert = false;
  startup = false;
}

@Injectable({
  providedIn: 'root'
})
export class CompanyListResolver extends BaseDataResolver {
  private http = inject(HttpService);
  private pd = inject(PartnersDirectoryService);
  private storage = inject(StorageService);
  private auth = inject(AuthService);

  protected override key = 'company-list';
  protected override model!: CompanyListModel;
  private isServices = false;

  override resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CompanyListModel> {
    this.isServices = route.data?.isServices;
    return super.resolve(route, state);
  }

  /**
   * Creates empty model
   */
  createModel(): CompanyListModel {
    return new CompanyListModel();
  }

  /**
   * Prepares company data
   * @param companies ICompany[] Companies list
   */
  prepareCompanies(companies: ICompany[]) {
    this.model.companies = companies;
  }

  /**
   * Loads filter parameters from params and query params
   * @param {ActivatedRouteSnapshot} route
   */
  override loadParams(route: ActivatedRouteSnapshot) {
    super.loadParams(route);

    this.model.sorting = this.model.query.sort || 't.asc';
    this.model.searchTerm = this.model.query.search || '';
    if (this.model.query.countries) {
      this.model.countries = this.model.query.countries;
    }
    this.model.isSolutions = this.model.query.isSolutions === '1';
    this.model.notWorldwide = this.model.query.notWorldwide === '1';
    this.model.isCertified = this.model.query.isCertified === '1';
    this.model.isAnyCert = this.model.query.isAnyCert === '1';
    this.model.startup = this.model.query.startup === '1';

    // Company types
    this.model.companyTypes = [];
    if (this.model.query.type) {
      const keys = this.model.query.type.split(';');
      this.model.companyTypes = [];
      keys.forEach(k => this.model.companyTypes[k] = true);
    }

    // Company certificates
    this.model.companyCerts = [];
    if (this.model.query.certificates) {
      const keys = this.model.query.certificates.split(';');
      this.model.companyCerts = [];
      keys.forEach(k => this.model.companyCerts[k] = true);
    }

    this.restoreAdminFiltering();
  }

  /**
   * Restores admin filtering
   */
  restoreAdminFiltering() {
    const af = this.storage.get('adminFiltres');
    if (!af) {
      return;
    }
    try {
      const o = JSON.parse(decodeURIComponent(af));
      if (o) {
        this.model.fltAdmin = o;
      }
    } catch (e) {
      console.error(`Can't restore admin filtering status`);
    }
  }

  /**
   * Loads company info
   */
  loadCompanies(): Promise<any> {
    return new Promise((res, rej) => {

      let url = `/mpapi/companies/find`;
      url += `?term=${this.model.searchTerm}&sorting=${this.model.sorting}`;

      // Show drafts for admins
      if (this.auth.isAdmin) {
        url += `&isDraft=${this.model.fltAdmin.isDraft ? 1 : 0}`;
        url += `&isPublished=${this.model.fltAdmin.isPublished ? 1 : 0}`;
        url += `&isWaiting=${this.model.fltAdmin.isWaiting ? 1 : 0}`;
      }

      if (this.model.startup) {
        url += `&startup=1`;
      }

      if (this.model.isSolutions) {
        url += `&isSolutions=1`;
      }

      if (this.model.notWorldwide) {
        url += `&notWorldwide=1`;
      }

      if (this.model.isCertified) {
        url += `&isCertified=1`;
      }

      if (this.model.companyTypes) {
        const selected = this.model.companyTypes;
        const types = Object.keys(selected).filter(k => selected[k]);
        url += '&type=' + types.join(';');
      }

      if (this.model.isAnyCert) {
        const certificates = this.model.certificates.map(c => c.ID);
        url += '&certificates=' + certificates.join(';');
      } else {
        if (this.model.companyCerts) {
          const selected = this.model.companyCerts;
          const certificates = Object.keys(selected).filter(k => selected[k]);
          url += '&certificates=' + certificates.join(';');
        }
      }

      if (this.model.countries) {
        url += `&countries=${decodeURIComponent(this.model.countries)}`;
      }

      if (this.isServices) {
        url += '&isServices=1';
      }

      // Request company data
      this.http.request(url)
        .then((companies: ICompany[]) => this.prepareCompanies(companies))
        .then(res)
        .catch(res);
    });
  }

  /**
   * Request data
   */
  requestData(): Promise<any> {
    return this.requestCertificates().then(() => {
      return this.loadCompanies();
    });
  }

  updateMetaTags() {
  }

  private requestCertificates() {
    return new Promise((res, rej) => {
      this.http.request('/mpapi/certificates')
        .then((certs: ICompanyCertificate[]) => this.prepareCertificates(certs))
        .then(res)
        .catch(res);
    });
  }

  private prepareCertificates(certs: ICompanyCertificate[]) {
    this.model.certificates = certs.sort((el1, el2) => el1.Order > el2.Order ? 1 : -1);
  }
}
