import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {HttpService} from '../../services/httpService';
import {ModalService} from '../../services/modal.service';
import { Injectable, LOCALE_ID, inject } from '@angular/core';
import {DatePipe} from '@angular/common';
import {ConfigService} from '../../services/configService';
import {CONTEST_DATE_FORMAT, ContestDetailsModel} from './contestDetailsData';
import {Observable} from 'rxjs/internal/Observable';

export interface IContestListItem {
  id: string;
  name: string;
  start: string;
  end: string;
  published: number;
  participants: number;

  // For UI
  current?: boolean;
  isVoting?: boolean;
  isRegistering?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ContestListData implements Resolve<IContestListItem[]> {
  private http = inject(HttpService);
  private modal = inject(ModalService);
  private locale = inject(LOCALE_ID);

  static isFirstRun = true;
  private current?: ContestDetailsModel;
  private model: IContestListItem[] = [];
  private datePipe: DatePipe;

  constructor() {
    const locale = this.locale;

    this.datePipe = new DatePipe(locale);
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return new Observable<any>(o => {
      const done = () => {
        o.next(this.model);
        o.complete();
      };

      this.requestCurrentContest().then(() => {
        // Request data
        this.requestData(route.queryParams.archive === '1')
          .then(list => {
            this.model = list;
            if (this.current) {
              this.model = [{
                start: this.current.voteStart,
                end: this.current.voteEnd,
                name: this.current.name,
                id: this.current.id,
                participants: this.current.participants.length,
                published: this.current.published,
                current: new Date(this.current.voteEnd.replace(/\s/, 'T')) > new Date(),
                isVoting: ConfigService.contest.voting === 1,
                isRegistering: ConfigService.contest.registration === 1
              }, ...this.model];
            }
            this.convertDates();
            done();
          })
          .catch(e => {
            this.modal.show(this.http.getErrorText(e));
          });
      });
    });
  }

  async requestCurrentContest() {
    const id = ConfigService.contest?.id;
    if (!id) {
      return Promise.resolve();
    }
    return this.http.request(`/mpapi/contest/get/${id}`)
      .then(contest => {
        this.current = contest;
      });
  }

  convertDates() {
    if (!this.model) {
      return;
    }
    for (let i = 0; i < this.model.length; i++) {
      const item = this.model[i];
      item.start = this.datePipe.transform(ConfigService.fromServerDate(item.start), CONTEST_DATE_FORMAT) ?? '';
      item.end = this.datePipe.transform(ConfigService.fromServerDate(item.end), CONTEST_DATE_FORMAT) ?? '';
      // Remove time
      item.start = item.start.split(',').slice(0, 2).join(',');
      item.end = item.end.split(',').slice(0, 2).join(',');
    }
  }

  /**
   * Request data
   */
  requestData(isArchive: boolean): Promise<IContestListItem[]> {
    return this.http.request(isArchive ? '/mpapi/contest/get/archive' : '/mpapi/contest/get/list');
  }
}
