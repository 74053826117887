import { Injectable, inject } from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {Meta} from '@angular/platform-browser';
import {BaseDataResolver, DataModel} from '../../services/base-data-resolver';
import {HttpService} from '../../services/httpService';
import {PartnersDirectoryService} from '../../services/partnersDirectory';
import {AuthService} from '../../services/authService';

export interface ITag {
  tag: string;
  count: number;
}

export class TagListModel extends DataModel {
  sorting = 'name';
  items: ITag[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class TagsResolver extends BaseDataResolver {
  private http = inject(HttpService);
  private pd = inject(PartnersDirectoryService);
  private meta = inject(Meta);
  private auth = inject(AuthService);

  protected override key = 'tags-list';
  protected override model!: TagListModel;

  override resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return super.resolve(route, state);
  }

  override loadParams(route: ActivatedRouteSnapshot) {
    super.loadParams(route);
    this.model.sorting = route.queryParamMap.get('sorting') || 'name';
  }

  /**
   * Creates empty model
   */
  createModel(): TagListModel {
    return new TagListModel();
  }

  /**
   * Prepares company data
   * @param companies ICompany[] Companies list
   */
  prepareTags() {
    this.model.items = this.model.items.sort((a, b) => {
      switch (this.model.sorting) {
        case 'name':
          return a.tag.localeCompare(b.tag);
        case 'count':
          return a.count < b.count ? 1 : -1;
      }
      return 0;
    });
  }

  /**
   * Request data
   */
  requestData(): Promise<any> {
    const url = `/mpapi/packages/tags`;

    // Request company data
    return this.http.request(url)
      .then((tags: ITag[]) => {
        this.model.items = tags.filter(t => t.tag && t.tag !== ' ');
        this.prepareTags();
      });
  }

  updateMetaTags() {
    const tagList = this.model?.items?.map(t => t.tag).join(', ');
    const img = 'https://openexchange.intersystems.com/assets/img/logo.svg';
    const title = this.pd.enabled ? 'Partner Directory' : 'Open Exchange';
    this.meta.updateTag({name: 'og:title', property: 'og:title', content: title + ' tags list'});
    this.meta.updateTag({name: 'og:description', property: 'og:description', content: tagList});
    this.meta.updateTag({name: 'og:image', property: 'og:image', content: img});
    this.meta.updateTag({name: 'og:image:secure_url', property: 'og:image:secure_url', content: img});
  }
}
