import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ElementRef, isSignal, ModelSignal, OnInit, ViewContainerRef, viewChild, input, inject } from '@angular/core';
import {IModalButton, ModalData, ModalService} from '../../services/modal.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';


@Component({
    selector: 'oex-modal',
    templateUrl: 'modal.html',
    imports: [],
    styleUrls: ['modal.scss']
})
export class ModalComponent implements OnInit {
  private ref = inject(ElementRef);
  private modals = inject(ModalService);
  private loader = inject(ComponentFactoryResolver);
  private san = inject(DomSanitizer);
  private cd = inject(ChangeDetectorRef);

  readonly data = input.required<ModalData>();
  readonly modalPlace = viewChild.required('component', { read: ViewContainerRef });

  public isLoad: Boolean = false;
  public isProgress: Boolean = false;
  messageHtml?: SafeHtml;

  ngOnInit() {
    const data = this.data();
    if (data.message) {
      data.message = data.message.replace(/\n/g, '<br/>');
    }

    if (data.useHtml) {
      this.messageHtml = this.san.bypassSecurityTrustHtml(data.message || '');
    }

    // Add buttons if not exists
    if (!data.buttons) {
      data.buttons = [<IModalButton>{text: 'Ok', default: true, clickOnEnter: true, cancel: true, close: true}];
    }

    // Create dynamic component in modal if specified
    if (data.compModel) {
      let res;
      if (data.compModel.component) {
        const factory = this.loader.resolveComponentFactory(data.compModel.component);
        res = this.modalPlace().createComponent(factory);
        data.component = res.instance;
        (<any>res.instance)._modal = this;
      }
      this.componentConfig(res, data.compModel);
      this.isLoad = true;
    } else {
      setTimeout(_ => {
        this.isLoad = true;
        this.cd.detectChanges();
      });
    }
  }

  componentConfig(inst: ComponentRef<any>, model) {
    function parseStyles(el, arr) {
      if (arr) {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].name) {
            // el.find(arr[i].name).css(arr[i].prop, arr[i].value);
          } else if (arr[i].className) {
            el.classList.add(arr[i].className);
          } else {
            // el.css(arr[i].prop, arr[i].value);
          }
        }
      }
    }

    if (inst?.instance) {
      // Check component as embedded, to know inside it how it shown
      inst.instance.embedded = true;
      for (const prop in model.props) {
        //if (typeof inst.instance[prop] === 'function' || inst.instance.hasOwnProperty(prop)) {
        if (isSignal(inst.instance[prop])) {
          inst.setInput(prop, model.props[prop]);
          //(inst.instance[prop] as ModelSignal<any>).set(model.props[prop]);
        } else {
          inst.instance[prop] = model.props[prop];
        }
        //}
      }
    }

    // styles correction
    if (model.styles) {
      if (inst?.location) {
        // correction of dynamic loaded component
        parseStyles(inst.location.nativeElement, model.styles.dynamicComponent);
      }
      // Correction of modal component
      parseStyles(this.ref.nativeElement, model.styles.nativeParent);
    }
  }

  close() {
    this.modals.close(this.data());
  }

  /**
   * Button click handler
   * @param {object} btn Button
   */
  onButtonClick(btn: IModalButton) {
    const data = this.data();
    if (btn.click) {
      btn.click(btn, data.component);
    }
    if (btn.close || btn.cancel) {
      this.modals.close(data);
    }
  }

  setErrorMessage(msg: string) {
    this.data().errorMessage = msg;
  }

  /**
   * Show progress indicator in modal window
   */
  showProgress() {
    this.isProgress = true;
  }

  /**
   * Hide progress indicator in modal window
   */
  hideProgress() {
    this.isProgress = false;
  }
}
