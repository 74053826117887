import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import {BreadcrumbService, IBreadcrumb} from '../../services/breadcrumb.service';
import {Subscription} from 'rxjs/internal/Subscription';
import {RouterLink} from '@angular/router';
import {SiteTrackerService} from '../../services/site-tracker.service';
import {ROUTE_MARKETPLACE} from '../../services/global-types';


@Component({
  selector: 'oex-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  imports: [
    RouterLink
  ],
  host: {
    '[class.hidden]': 'isHidden'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit, OnDestroy {
  private brs = inject(BreadcrumbService);
  st = inject(SiteTrackerService);
  private cd = inject(ChangeDetectorRef);

  items: IBreadcrumb[] = [];
  mpRoute = ROUTE_MARKETPLACE;
  private subBreadcrumbsChanged?: Subscription;

  protected get isHidden() {
    return this.items.length === 0;
  }

  ngOnInit() {
    this.subBreadcrumbsChanged = this.brs.onBreadcrumbsChanged.subscribe(list => this.setBreadcrumbs(list));
  }

  ngOnDestroy() {
    this.subBreadcrumbsChanged?.unsubscribe();
  }

  onItemClick(b: IBreadcrumb) {
    this.brs.onBreadcrumbPressed.emit(b);
  }

  private setBreadcrumbs(list: IBreadcrumb[]) {
    this.items = list;
    this.cd.detectChanges();
  }
}
