import {CanActivateFn, Router, Routes, RunGuardsAndResolvers} from '@angular/router';
import {PackageDetailsResolver} from './pages/package/packageDetailsData';
import {HomeResolver} from './pages/home/homeData';
import {TagsResolver} from './pages/tags/tags-resolver';
import {ReleasesListResolver} from './pages/releases/releases-list-data';
import {CompanyDetailsResolver} from './pages/company/company-details-data';
import {UserDataResolver} from './pages/account/userData';
import {DefaultResolver} from './default-resolver';
import {AuthService, guardAuthIsCompanyViewer, guardAuthIsLogged} from './services/authService';
import {resolveMuteData} from './pages/mute/muteData';
import {resolveHomePDData} from './partner/home/home-pd-data.resolver';
import {CompanyListResolver} from './pages/company/company-list-data';
import {resolveUnsubscribeData} from './pages/unsubscribe/unsubscribeData';
import {ROUTE_MARKETPLACE} from './services/global-types';
import {hihSidebarItems} from './services/sidebars/hih-sidebar';
import {resolveSavedSearches} from './hih/saved-searches/saved-searches.component';
import {resolveInquiries} from './hih/inquires/inquires.component';
import {hihRecommendationsResolver} from './hih/recommendations/recommendations.component';
import {resolveSavedProducts} from './hih/saved-products/saved-products.component';
import {ADMIN_PANEL_ROUTE} from './app.routes.admin';
import {pdSidebarItems} from './services/sidebars/pd-portal-sidebar';
import {companyEditorCanDeactivateResolver, resolveCompanyModel} from './pages/company/my-company';
import {packageEditorCanDeactivateResolver} from './pages/package/package-editor/package-editor.component';
import {startupCanActivateGuard} from './services/startup-redirect.guard';
import {ModalComponent} from './controls/modal/modal';
import {inject} from '@angular/core';

const checkBecomePartner: CanActivateFn = (route, state) => {
  const auth = inject(AuthService);
  const router = inject(Router);
  if (!auth.isLogged) {
    return true;
  }
  const comp = auth.getAdministratedCompany();
  return router.navigate([comp ? `/portal/company/${comp.nws}` : `/portal/company`]);
}

const PORTAL_CHILDREN: Routes = [
  {
    path: '',
    loadComponent: () => import('./portal/home/portal-home.screen').then(m => m.PortalHomeScreenComponent)
  },
  {
    path: 'profile',
    title: 'Profile',
    children: [
      {
        path: 'personal',
        title: 'Personal Data',
        loadComponent: () => import('./pages/account/profile-editor').then(m => m.ProfileEditorScreenComponent)
      },
      {
        path: 'subscriptions',
        title: 'Subscriptions',
        loadComponent: () => import('./pages/account/subscriptions').then(m => m.SubscriptionsScreenComponent)
      },
      {
        path: 'reviews',
        title: 'reviews',
        loadComponent: () => import('./pages/account/my-reviews').then(m => m.MyReviewsScreenComponent)
      },
      {
        path: 'ecosystem-services',
        title: 'Ecosystem Services',
        loadComponent: () => import('./pages/account/promotion/promotion').then(m => m.PromotionComponent)
      }
    ]
  },
  {
    path: 'members',
    title: 'Members',
    loadComponent: () => import('./pages/company/company-members').then(m => m.CompanyMembersComponent)
  },
  {
    path: 'stories',
    title: 'Success Stories',
    loadComponent: () => import('./pages/company/stories-list/stories-list').then(m => m.StoriesListComponent)
  },
  {
    path: 'products',
    title: 'Products',
    children: [
      {
        path: '',
        loadComponent: () => import('./portal/my-products/my-products').then(m => m.MyProductsScreenComponent)
      },
      {
        path: ':id/edit',
        title: 'Edit Product',
        loadComponent: () => import('./pages/package/package-editor/package-editor.component').then(m => m.PackageEditorComponent),
        canDeactivate: [packageEditorCanDeactivateResolver],
        resolve: {model: PackageDetailsResolver}
      },
      {
        path: ':id/analytics',
        title: 'Analytics',
        loadComponent: () => import('./pages/package-downloads/packageDownloads').then(m => m.PackageDownloadsComponent),
        data: {
          ignoreChildNWS: true
        },
        resolve: {model: PackageDetailsResolver}
      },
      {
        path: ':id/releases',
        title: 'Releases',
        loadComponent: () => import('./pages/versionHistory/versionHistory').then(m => m.VersionHistoryComponent),
        resolve: {model: PackageDetailsResolver},
        data: {
          ignoreChildNWS: true
        }
      },
      {
        path: ':id/reviews',
        title: 'PortalPackageReviews',
        loadComponent: () => import('./pages/reviews/reviews-list.component').then(m => m.ReviewsListComponent),
        data: {ignoreChildNWS: true},
        resolve: {model: PackageDetailsResolver}
      }
    ]
  },
  {
    path: 'statistics',
    title: 'Statistics',
    loadComponent: () => import('./pages/account/my-apps/my-apps').then(m => m.MyAppsScreenComponent)
  },
  {
    path: 'messages',
    title: 'Messages',
    loadComponent: () => import('./portal/messages/portal-messages.component').then(m => m.PortalMessagesComponent)
  },
  {
    path: 'reviews',
    title: 'Reviews',
    loadComponent: () => import('./pages/account/my-reviews').then(m => m.MyReviewsScreenComponent)
  },
  {
    path: 'certification',
    title: 'Certification',
    loadComponent: () => import('./portal/certs/portal-certs.screen').then(m => m.PortalCertsScreenComponent)
  },
  {
    path: 'marketing-tools',
    title: 'Marketing Tools',
    loadComponent: () => import('./admin/marketing-tools/marketing-tools.screen').then(m => m.AdminMarketingToolsScreenComponent)
  },
  {
    path: 'services',
    title: 'Services',
    loadComponent: () => import('./pages/company/service/company-services').then(m => m.CompanyServicesComponent)
  },
  {
    path: 'bookmarks',
    title: 'Bookmarks',
    loadComponent: () => import('./portal/my-bookmarks/my-bookmarks.screen').then(m => m.MyBookmarksScreenComponent)
  },
  {
    path: 'inquiries',
    title: 'Health Innovation Hub Inquiries',
    loadComponent: () => import('./hih/inquires/inquires.component').then(m => m.InquiresComponent),
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    data: {
      publisher: true
    },
    resolve: {
      data: resolveInquiries
    }
  },
  /* {
     path: 'benefits/intersystems',
     component: PortalBenefitsISScreenComponent
   },
   {
     path: 'benefits/training',
     component: PortalTrainingScreenComponent
   },
   {
     path: 'benefits/support',
     component: PortalSupportScreenComponent
   },*/
  {
    path: 'benefits',
    title: 'Partner Benefits',
    children: [
      {
        path: '',
        title: 'Overview',
        loadComponent: () => import('./portal/benefits-is/benefits-is.screen').then(m => m.PortalBenefitsISScreenComponent)
      },
      {
        path: 'intersystems',
        title: 'Software',
        loadComponent: () => import('./admin/benefits/admin-benefits').then(m => m.AdminBenefitsComponent)
      },
      {
        path: 'training',
        title: 'Training',
        loadComponent: () => import('./admin/benefits/admin-benefits').then(m => m.AdminBenefitsComponent)
      },
      {
        path: 'support',
        title: 'Support',
        loadComponent: () => import('./admin/benefits/admin-benefits').then(m => m.AdminBenefitsComponent)
      },
      {
        path: 'marketing',
        title: 'Marketing',
        loadComponent: () => import('./admin/benefits/admin-benefits').then(m => m.AdminBenefitsComponent)
      },
      {
        path: 'reports',
        title: 'Reports',
        loadComponent: () => import('./admin/benefit-reports/benefit-reports.screen').then(m => m.AdminBenefitReportsScreenComponent)
      }
    ]
  },
  {
    path: 'events',
    title: 'Events',
    loadComponent: () => import('./portal/events/portal-events.screen').then(m => m.PortalEventsScreenComponent)
  },
  {
    path: 'contacts',
    title: 'Contact Us',
    loadComponent: () => import('./portal/contacts/portal-contacts.screen').then(m => m.PortalContactsScreenComponent)
  },
  {
    path: 'faq',
    title: 'Faq',
    loadComponent: () => import('./portal/faq/portal-faq.screen').then(m => m.PortalFaqScreenComponent)
  },
  {
    path: 'startup',
    title: 'Startup Program',
    loadComponent: () => import('./portal/startup/portal-startup.screen').then(m => m.PortalStartupScreenComponent)
  },
  {
    path: 'company',
    title: 'Company',
    loadComponent: () => import('./pages/company/my-company').then(m => m.MyCompanyComponent),
    canDeactivate: [companyEditorCanDeactivateResolver],
    data: {fromRoute: true},
    resolve: {
      model: resolveCompanyModel
    }
  },
  {
    path: 'join',
    title: 'Join',
    loadComponent: () => import('./portal/join/portal-join-company.screen').then(m => m.PortalJoinCompanyScreenComponent)
  },
  {
    path: 'company/:nws',
    title: 'Company',
    loadComponent: () => import('./pages/company/my-company').then(m => m.MyCompanyComponent),
    canDeactivate: [companyEditorCanDeactivateResolver],
    resolve: {
      model: resolveCompanyModel
    },
    runGuardsAndResolvers: 'paramsChange',
    data: {fromRoute: true}
  }
];

/**
 * Router setup
 */
export const routesPD: Routes = [
  {
    path: '',
    title: 'HomePD',
    loadComponent: () => import('./partner/home/home-pd').then(m => m.HomeScreenPD),
    resolve: {model: resolveHomePDData},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'tags',
    title: 'Tags',
    loadComponent: () => import('./pages/tags/tags-screen.component').then(m => m.TagsScreenComponent),
    resolve: {model: TagsResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'solutions',
    title: 'Solutions',
    loadComponent: () => import('./partner/home/solutions.pd').then(m => m.PDSolutionsScreenComponent),
    resolve: {model: HomeResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'apps-test',
    title: 'SolutionsTest',
    loadComponent: () => import('./partner/home/solutions.pd').then(m => m.PDSolutionsScreenComponent),
    resolve: {model: HomeResolver},
    runGuardsAndResolvers: 'always'
  },
 /* {
    path: 'index.html',
    title: 'Home',
    loadComponent: () => import('./partner/home/solutions.pd').then(m => m.PDSolutionsScreenComponent),
    resolve: {model: HomeResolver},
    runGuardsAndResolvers: 'always'
  },*/
  // Temporary redirect, requested by (#1353)
  {
    path: 'package/InterSystems-Package-Manager',
    redirectTo: 'package/InterSystems-Package-Manager-1'
  },
  {
    path: 'package/:id',
    title: 'Package',
    loadComponent: () => import('./pages/package/packageDetails').then(m => m.PackageDetailsScreenComponent),
    resolve: {model: PackageDetailsResolver}
  },
  {
    path: 'releases',
    title: 'Releases',
    loadComponent: () => import('./pages/releases/releases-list').then(m => m.ReleasesListComponent),
    resolve: {model: ReleasesListResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'companies',
    title: 'Companies',
    loadComponent: () => import('./partner/company/company-list.pd').then(m => m.PDCompaniesScreenComponent),
    resolve: {model: CompanyListResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'services',
    title: 'Services',
    loadComponent: () => import('./partner/company/company-list.pd').then(m => m.PDCompaniesScreenComponent),
    resolve: {model: CompanyListResolver},
    data: {
      isServices: true
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'members',
    title: 'Members',
    loadComponent: () => import('./pages/members/members').then(m => m.MembersScreenComponent)
    // resolve: {model: CompanyDetailsResolver},
    // runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
  },
  {
    path: 'company/:company',
    title: 'Company',
    loadComponent: () => import('./pages/company/company-details').then(m => m.CompanyDetailsComponent),
    resolve: {model: CompanyDetailsResolver},
    runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
  },
  {
    path: 'search/:term',
    title: 'Search',
    loadComponent: () => import('./partner/home/solutions.pd').then(m => m.PDSolutionsScreenComponent),
    resolve: {model: HomeResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'user/:name/:key',
    title: 'UserInfo',
    loadComponent: () => import('./pages/account/user').then(m => m.UserScreenComponent),
    resolve: {model: UserDataResolver}
  },
  /*{
    path: 'profile/:name/:key',
    title: 'UserProfile',
    loadComponent: () => import('./pages/account/profile').then(m => m.ProfileScreenComponent),
    resolve: {model: DefaultResolver},
    canActivate: [AuthService]
  },
  {
    path: 'profile/edit',
    title: 'ProfileEditor',
    loadComponent: () => import('./pages/account/profile-editor').then(m => m.ProfileEditorScreenComponent),
    resolve: {model: DefaultResolver},
    canActivate: [AuthService]
  },*/
  {
    path: 'package/new',
    title: 'PackageNew',
    loadComponent: () => import('./pages/package/packageDetails').then(m => m.PackageDetailsScreenComponent),
    resolve: {model: DefaultResolver},
    canActivate: [AuthService]
  },
  {
    path: 'news',
    title: 'News',
    loadComponent: () => import('./pages/news/news').then(m => m.NewsScreenComponent),
    resolve: {model: DefaultResolver}
  },
  {
    path: 'startupgrowthtoolkits',
    loadComponent: () => import('./partner/startup-program/startup-program.pd').then(m => m.PDStartupProgramScreenComponent),
    resolve: {model: DefaultResolver}
  },
  {
    path: 'startup-program',
    canActivate: [startupCanActivateGuard],
    component: ModalComponent
  },
  {
    path: 'for-startups',
    canActivate: [startupCanActivateGuard],
    component: ModalComponent
  },
  /*
    {path: 'top', title: 'TopDownloaded', component: PDSolutionsScreenComponent, resolve: {model: HomeResolver}, runGuardsAndResolvers: 'always'},
    {path: 'topRated', title: 'TopRated', component: PDSolutionsScreenComponent, resolve: {model: HomeResolver}, runGuardsAndResolvers: 'always'},
  */
  {
    path: 'markdown',
    title: 'Markdown',
    loadComponent: () => import('./pages/markdown/markdown').then(m => m.MarkdownScreenComponent)
  },
  {
    path: 'about',
    title: 'About',
    loadComponent: () => import('./partner/about/about.pd').then(m => m.PDAboutScreenComponent),
    // resolve: {model: LandingDataResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'venture-fund',
    title: 'VentureFund',
    loadComponent: () => import('./partner/fund-landing/fund-landing.component').then(m => m.VentureFundLandingComponent)
  },
  {
    path: 'become-a-partner',
    title: 'BecomeAPartner',
    canActivate: [checkBecomePartner],
    loadComponent: () => import('./partner/become-a-partner/become-a-partner').then(m => m.PDBecomeAPartnerComponent),
    // resolve: {model: LandingDataResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'sub/suspend/:key',
    title: 'Unsubscribe',
    loadComponent: () => import('./pages/unsubscribe/unsubscribe').then(m => m.UnsubscribeScreenComponent),
    resolve: {model: resolveUnsubscribeData},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'sub/mute/:key/:sid',
    title: 'Mute',
    loadComponent: () => import('./pages/mute/mute').then(m => m.MuteScreenComponent),
    resolve: {model: resolveMuteData},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'technology-partners-listing',
    title: 'Tech',
    loadComponent: () => import('./partner/about/tech.partners.pd').then(m => m.PDTechScreenComponent)
  },
  {
    path: 'terms',
    title: 'Terms',
    loadComponent: () => import('./pages/terms/terms-screen.component').then(m => m.TermsScreenComponent)
  },
  {
    path: 'view-company/:viewCompanyPortalNws/portal',
    loadComponent: () => import('./../oex-ui-kit/components/portal-page/portal-page.component').then(m => m.PortalPageComponent),
    resolve: {
      sidebar: pdSidebarItems
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [guardAuthIsLogged, guardAuthIsCompanyViewer],
    children: PORTAL_CHILDREN
  },
  {
    path: 'portal',
    title: 'Portal',
    loadComponent: () => import('./../oex-ui-kit/components/portal-page/portal-page.component').then(m => m.PortalPageComponent),
    resolve: {
      sidebar: pdSidebarItems
    },
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    canActivate: [guardAuthIsLogged],
    children: PORTAL_CHILDREN
  },
  {
    path: 'preview', children: [
      {
        path: 'company/:company',
        loadComponent: () => import('./pages/preview/ssr-card-preview').then(m => m.SSRCardPreviewComponent),
        resolve: {model: CompanyDetailsResolver},
        runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
      },
      {
        path: 'package/:id',
        loadComponent: () => import('./pages/preview/ssr-card-preview').then(m => m.SSRCardPreviewComponent),
        resolve: {model: PackageDetailsResolver},
        runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
      },
      {
        path: 'member/:name/:key',
        loadComponent: () => import('./pages/preview/ssr-card-preview').then(m => m.SSRCardPreviewComponent),
        resolve: {model: UserDataResolver},
        runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
      }
    ]
  },
  {
    path: 'map',
    loadComponent: () => import('./pages/company/companies-map/companies-map').then(m => m.CompaniesMapComponent)
  },
  // Marketplace
  {
    path: ROUTE_MARKETPLACE,
    title: 'Health Innovation Hub',
    children: [
      {
        path: '',
        redirectTo: 'solutions',
        pathMatch: "full"
      },
      {
        path: 'solutions',
        title: 'InterSystems Health Innovation Hub',
        loadComponent: () => import('./partner/home/solutions.pd').then(m => m.PDSolutionsScreenComponent),
        resolve: {model: HomeResolver},
        runGuardsAndResolvers: 'always'
      },
      {
        path: 'package/:id',
        title: 'Solution',
        loadComponent: () => import('./pages/package/packageDetails').then(m => m.PackageDetailsScreenComponent),
        resolve: {model: PackageDetailsResolver}
      },
      {
        path: 'company/:company',
        title: 'Company',
        loadComponent: () => import('./pages/company/company-details').then(m => m.CompanyDetailsComponent),
        resolve: {model: CompanyDetailsResolver},
        runGuardsAndResolvers: 'paramsChange' as RunGuardsAndResolvers
      },
      {
        path: 'portal',
        title: 'Buyer Portal',
        loadComponent: () => import('./../oex-ui-kit/components/portal-page/portal-page.component').then(m => m.PortalPageComponent),
        resolve: {
          sidebar: hihSidebarItems
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange',
        children: [
          {
            path: '',
            redirectTo: 'overview',
            pathMatch: 'full'
          },
          {
            path: 'overview',
            title: 'Overview',
            loadComponent: () => import('./hih/overview/hih-overview.component').then(m => m.HihOverviewComponent),
          },
          {
            path: 'company',
            title: 'Your organisation profile',
            loadComponent: () => import('./pages/company/my-company').then(m => m.MyCompanyComponent),
            resolve: {
              model: resolveCompanyModel
            },
            canDeactivate: [companyEditorCanDeactivateResolver]
          },
          {
            path: 'recommendations',
            title: 'Recommended to you',
            loadComponent: () => import('./hih/recommendations/recommendations.component').then(m => m.RecommendationsComponent),
            resolve: {
              data: hihRecommendationsResolver
            }
          },
          {
            path: 'searches',
            title: 'Saved Searches',
            loadComponent: () => import('./hih/saved-searches/saved-searches.component').then(m => m.SavedSearchesComponent),
            resolve: {
              data: resolveSavedSearches
            }
          },
          {
            path: 'favorites',
            title: 'Favorites',
            loadComponent: () => import('./hih/saved-products/saved-products.component').then(m => m.SavedProductsComponent),
            resolve: {
              data: resolveSavedProducts
            }
          },
          {
            path: 'inquiries',
            title: 'My Inquiries',
            loadComponent: () => import('./hih/inquires/inquires.component').then(m => m.InquiresComponent),
            runGuardsAndResolvers: 'paramsOrQueryParamsChange',
            resolve: {
              data: resolveInquiries
            }
          },
          {
            path: 'profile',
            title: 'My Profile',
            loadComponent: () => import('./pages/account/profile-editor').then(m => m.ProfileEditorScreenComponent)
          },
          {
            path: 'contact',
            title: 'Contact InterSystems',
            loadComponent: () => import('./portal/contacts/portal-contacts.screen').then(m => m.PortalContactsScreenComponent)
          }
        ]
      },
    ]
  },
  ADMIN_PANEL_ROUTE,
  {
    path: '**',
    loadComponent: () => import('./pages/not-found-page/not-found-page.component').then(m => m.NotFoundScreenComponent)
  }
];
