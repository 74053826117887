<!-- Modal title -->
<div class="title" [hidden]="!data().title">{{data().title}}</div>

<!-- Modal content -->
<div [hidden]="data().compModel?.component || data().useHtml" class="message">
  {{data().message}}
</div>

<!-- Html content -->
<div [hidden]="data().compModel?.component || !data().useHtml" class="message" [innerHTML]="messageHtml">
</div>

<div [hidden]="!data().compModel?.component" class="message" [style.margin-bottom.px]="!data().buttons?.length ? 0 : null">
  <div #component></div>
</div>

<!-- Buttons -->
<div class="footer" [hidden]="!isLoad || !(data().buttons && data().buttons?.length !== 0)">
  @for (btn of data().buttons; track btn) {
    <div class="btn" [class.green]="btn.default" [class.blue]="!btn.default"
      (click)="onButtonClick(btn)"
      >{{btn.text}}
    </div>
  }
</div >
